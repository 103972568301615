const versionConfig = {
	UNIT_VARIABLES: true,
	CONSO: true,
	EXPORT_PROJECT_DATA: false,
	IMPORT_PROJECT_DATA: false,
	IMPORT_BPITEMS_XLS: true,
	// PREVI_UX_V3: true,
	CUSTOM_TABLES: true,
	BP_ITEMS_DATES_WARNING: true,
	NEW_BUTTONS: true,
	BP_ITEM_TAGS: true,
	NEW_ACCOUNT_SCHEDULES: false,
	BP_TEMPLATES: false,
};

export default versionConfig;
