import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import "./App.css";
import Routes from "./Routes";
import config from "./config";
import {OverlayTrigger, Popover} from "react-bootstrap";
import axios from "axios";
import bowser from "bowser";
import {UserAuthContext} from "./UserAuthContext";
import dayjs from "./components/dayjs-extended";
import {/*crmLogViewTuto,*/ crmLogProjectStep, crmLogSavePersonalData, crmLogHelpDeskData} from "./components/crmEvents";
import {navToExternalLink, testIsNewCompany} from "./components/utils";
import sessionParameters from "./containers/session-parameters";
import Helmet from "react-helmet";
import HelpDeskBtn from "./components/HelpDeskBtn";
import {defaultCompanyParameters} from "./components/misc-parameters";
import {AppThemeManager, whiteLabelCustomItemNames, whiteLabelCustomItemStyles, defaultAppStyles} from "./components/AppThemeManager";
import {FeedbackManager} from "./components/UserFeedbacks";
import {isValidHexColor, hexToRgb} from "./components/color-utils";

class App extends Component {
	constructor(props) {
		super(props);

		const hostname = window.location.hostname.toLowerCase();

		window.appName = "evaltonbiz";

		if (hostname.indexOf("ysibiz") > -1) {
			window.appName = "ysibiz";
		} else if (hostname.indexOf("forekasts.africa") > -1) {
			window.appName = "forekasts-africa";
		} else if (hostname.indexOf("ebizview") > -1) {
			window.appName = "forekasts-africa";
		} else if (hostname.indexOf("previsionnel.app") > -1) {
			const urlParts = hostname.split(".");
			if (urlParts && urlParts[0]) {
				window.appName = urlParts[0];
			}
		} else if (hostname.indexOf("forekasts.app") > -1) {
			const urlParts = hostname.split(".");
			if (urlParts && urlParts[0]) {
				window.appName = urlParts[0];
			}
		} else if (hostname.indexOf("bdo") > -1) {
			window.appName = "bdo";
		}
		// window.appName = "ysibiz";
		// window.appName = "forekasts-africa";
		// window.appName = "cab-test1";
		// window.appName = "innest-test";
		// window.appName = "cab-test-27";
		// window.appName = "previbiz";
		// window.appName = "bdo";

		window.serverName = "aws";
		if (hostname.indexOf("alpharlh") > -1) {
			window.serverName = "ows";
		}
		// window.serverName = "ows";

		this.initSubscriptionData = {
			clientSubscriptions: null,
			clientCurrentSubscription: null,
			clientCurrentOptions: null,
			clientLastSubscription: null,
			// clientCurrentCompaniesExtras: null,
			// clientCurrentSubscriptionHost: null,
			userAppAccess: {
				profile: null,
				// level: null,
				maxCompanies: 0,
				secondaryAccounts: 0,
				freeTrial: null,
				invited: null,
				isSecondaryAccount: null,
				secondaryAccountEndAccessDate: null,
				endDate: null,
				companiesManagementAuthorised: null,
				featuresAccess: {},
				canceled: null,
			},
		};

		const isWhiteLabel = !config.ownAppNames.includes(window.appName);

		this.defaultMicroCompanyType = "services";

		this.state = {
			userToken: null,
			userCode: null,
			adminMonitoredUserCode: null,
			adminMonitoredUserData: null,
			userFirstName: null,
			userLastName: null,
			userFirm: null,
			userCompanyName: null,
			userSiren: null,
			isCompanyOwner: true,
			isCompanyAccessLimited: false,
			isCompanyAccessExpired: false,
			companyAccessExpirationDate: null,
			isCompanyAccessSuspended: false,
			// isReportExportLocked: false,
			accountsUuid: null,
			fecUuid: null,
			chartOfAccounts: null,
			isNewCompany: null,
			startAsNewCompany: null,
			newCompanyFirstClosingYear: null,
			newCompanyClosingMonth: null,
			newCompanyStartYear: null,
			newCompanyStartMonth: null,
			newCompanyFirstPeriodLength: null,
			newCompanyLongFirstFiscalYear: null,
			companyUseCase: null,
			userEmail: null,
			userJob: null,
			userPhone: null,
			isLoadingUserToken: true,
			isLoadingUserActivation: true,
			isLoadingClientSubscriptions: true,
			isLoadingUserSiren: true,
			isLoadingUserProfile: true,
			isLoadingUserSessionFlag: true,
			isLoadingUserCompanySessionFlag: true,
			userUserSessionFlag: null,
			userCompanySessionFlag: null,
			userProfile: null,
			isSuperAdminProfile: false,
			isAdminProfile: false,
			userType: null,
			userCreationDate: null,
			userActivation: "0",
			...this.initSubscriptionData,
			isStripeConnectionDown: false,
			// showCompanyTuto: false,
			// showProjectTuto: false,
			// showSimulTuto: false,
			// countryCode: "00000",
			countryCode: (typeof defaultCompanyParameters[window.appName] !== "undefined" ? defaultCompanyParameters[window.appName] : defaultCompanyParameters["default"]).countryCode,
			deptCode: null,
			currencyCode: (typeof defaultCompanyParameters[window.appName] !== "undefined" ? defaultCompanyParameters[window.appName] : defaultCompanyParameters["default"]).currencyCode,
			currencySymbol: (typeof defaultCompanyParameters[window.appName] !== "undefined" ? defaultCompanyParameters[window.appName] : defaultCompanyParameters["default"])
				.currencySymbol,
			currencyText: (typeof defaultCompanyParameters[window.appName] !== "undefined" ? defaultCompanyParameters[window.appName] : defaultCompanyParameters["default"]).currencyText,
			companyLocalId: null,
			companyLegalFormCode: null,
			companySubsidiaries: null,
			companyParentCompany: null,
			// isMicroCompany: false,
			// microCompanyType: this.defaultMicroCompanyType,
			// logoSrc: "",
			isWhiteLabel: isWhiteLabel,
			isCoBranded: false,
			isAppNameActive: true,
			whiteLabelAppName: config.appParams[window.appName] ? config.appParams[window.appName].capitalizedName : "",
			loginPageVersion: null,
			loginPageUrl: null,
			customerUrl: "",
			faviconImage: null,
			navbarImage: null,
			loginPageTagline: null,
			loginPageLogoWidth: null,
			onlineSupport: true,
			dedicatedSupportEmail: true,
			// sideMenuMainBgColor: null,
			// sideMenuSecondaryBgColor: null,
			// sideMenuEmphasisColor: null,
			isLoadingWhiteLabelData: isWhiteLabel,
			showAppThemeManager: false,
			browserInfo: {
				os: "nd",
				name: "nd",
				version: "nd",
				platform: "nd",
			},
			consolidatedView: false,
			showTour: null,
			showFeedback: null,
			appStyles: {
				...defaultAppStyles,
			},
		};

		// this.browserInfo = {
		// os: "nd",
		// name: "nd",
		// version: "nd",
		// platform: "nd"
		// };
	}

	componentDidMount() {
		// console.log(window.location);

		// Pour le tracking

		const {parsedResult} = bowser.getParser(window.navigator.userAgent);
		// console.log(parsedResult);
		// alert(JSON.stringify(parsedResult, null, 2));
		if (parsedResult) {
			this.setState({
				browserInfo: {
					os: parsedResult.os.name,
					name: parsedResult.browser.name,
					version: parsedResult.browser.version,
					platform: parsedResult.platform.type,
				},
			});

			window.osName = parsedResult.os.name;

			document.documentElement.setAttribute("data-browser", parsedResult.browser.name);
		}

		this.touchDevice = !!(window.navigator.maxTouchPoints || "ontouchstart" in window.document.documentElement);
		document.documentElement.setAttribute("touch-device", this.touchDevice);

		if ((!this.state.isWhiteLabel || (this.state.isCoBranded && this.state.onlineSupport)) && window.appName !== "forekasts-africa") {
			this.initTawkApp();
		}

		const userToken = localStorage.getItem("token");

		const curPagePathName = this.props.location.pathname;

		// Anciennes couleurs menu
		if (window.appName === "ysibiz") {
			const root = document.querySelector(":root");
			if (root) {
				whiteLabelCustomItemNames.forEach((themeItemName) => {
					if (whiteLabelCustomItemStyles[themeItemName]) {
						root.style.setProperty("--fk-" + themeItemName, whiteLabelCustomItemStyles[themeItemName]);
					}
				});
			}
		}

		// Params white label

		if (this.state.isWhiteLabel) {
			if (curPagePathName === "/signup") {
				this.props.history.push("/login");
			}

			const apiBaseUrl = config.api.server[window.serverName];
			const reqConfig = {
				headers: {"x-access-token": userToken, appname: window.appName},
			};
			axios
				.get(apiBaseUrl + config.api.root + "/white-label/" + window.appName + "/public-app-data", reqConfig)
				.then((resp) => {
					// console.log(resp);

					// const wlAppData = {
					// isCoBranded: false,
					// sideMenuMainBgColor: null,
					// sideMenuSecondaryBgColor: null,
					// sideMenuEmphasisColor: null,
					// };

					if (resp && resp.data.success && resp.data.data) {
						const appData = resp.data.data;

						if (appData.status === false) {
							// white label désactivé
							this.setState({
								isAppNameActive: false,
								isCoBranded: false,
								whiteLabelAppName: "",
								loginPageVersion: null,
								loginPageUrl: null,
								customerUrl: "",
								faviconImage: null,
								navbarImage: null,
								loginPageTagline: null,
								loginPageLogoWidth: null,
								onlineSupport: true,
								dedicatedSupportEmail: true,
								// sideMenuMainBgColor: null,
								// sideMenuSecondaryBgColor: null,
								// sideMenuEmphasisColor: null,
								isLoadingWhiteLabelData: false,
							});
						} else {
							this.setState({
								isAppNameActive: true,
								isCoBranded: appData.isCoBranded,
								whiteLabelAppName: appData.appName,
								loginPageVersion: appData.loginPageVersion,
								loginPageUrl: appData.loginPageUrl,
								customerUrl: appData.customerUrl,
								faviconImage: appData.faviconImage,
								navbarImage: appData.navbarImage,
								loginPageTagline: appData.loginPageTagline,
								loginPageLogoWidth: appData.loginPageLogoWidth,
								onlineSupport: appData.onlineSupport,
								dedicatedSupportEmail: appData.dedicatedSupportEmail,
								// sideMenuMainBgColor: appData.sideMenuMainBgColor,
								// sideMenuSecondaryBgColor: appData.sideMenuSecondaryBgColor,
								// sideMenuEmphasisColor: appData.sideMenuEmphasisColor,
								isLoadingWhiteLabelData: false,
							});

							if (!appData.isCoBranded) {
								/*
							newState.appStyles = {...this.state.appStyles};
							
							const root = document.querySelector(":root");
							
							appData.styles.forEach(({styleItemName, styleItemValue}) => {
								if (styleItemValue && isValidHexColor(styleItemValue)) {
									const {r, g, b} = hexToRgb(styleItemValue);
									const rgbString = r + ", " + g + ", " + b;
									newState.appStyles["-" + styleItemName] = styleItemValue;
									newState.appStyles["-" + styleItemName + "-rgb"] = rgbString;
									if (root) {
										root.style.setProperty("--fk-" + styleItemName, styleItemValue);
										root.style.setProperty("--fk-" + styleItemName + "-rgb", rgbString);
									}
								}
								else if (whiteLabelCustomItemStyles["--fk-" + styleItemName]) {
									const {r, g, b} = hexToRgb(whiteLabelCustomItemStyles["--fk-" + styleItemName]);
									const rgbString = r + ", " + g + ", " + b;
									newState.appStyles["-" + styleItemName] = whiteLabelCustomItemStyles["--fk-" + styleItemName];
									newState.appStyles["-" + styleItemName + "-rgb"] = rgbString;
									if (root) {
										root.style.setProperty("--fk-" + styleItemName, whiteLabelCustomItemStyles["--fk-" + styleItemName]);
										root.style.setProperty("--fk-" + styleItemName + "-rgb", rgbString);
									}
								}
							});
							*/

								const tmpStyles = {...whiteLabelCustomItemStyles};

								if (appData.styles) {
									appData.styles.forEach(({styleItemName, styleItemValue}) => {
										if (styleItemValue && isValidHexColor(styleItemValue)) {
											tmpStyles[styleItemName] = styleItemValue;
										}
									});
								}

								const styles = Object.keys(tmpStyles).map((styleItemName) => ({
									styleItemName: styleItemName,
									styleItemValue: tmpStyles[styleItemName],
								}));

								this.handleUpdateAppStyles(styles);
							}

							// wlAppData.isCoBranded = appData.isCoBranded;
							// wlAppData.sideMenuMainBgColor = appData.sideMenuMainBgColor;
							// wlAppData.sideMenuSecondaryBgColor = appData.sideMenuSecondaryBgColor;
							// wlAppData.sideMenuEmphasisColor = appData.sideMenuEmphasisColor;

							// this.setState(newState);
						}
					} else {
						this.setState({
							isAppNameActive: false,
							isCoBranded: false,
							whiteLabelAppName: "",
							loginPageVersion: null,
							loginPageUrl: null,
							customerUrl: "",
							faviconImage: null,
							navbarImage: null,
							loginPageTagline: null,
							loginPageLogoWidth: null,
							onlineSupport: true,
							dedicatedSupportEmail: true,
							// sideMenuMainBgColor: null,
							// sideMenuSecondaryBgColor: null,
							// sideMenuEmphasisColor: null,
							isLoadingWhiteLabelData: false,
						});
					}

					// return wlAppData;
				})
				.catch((err) => {
					console.log(err);
					this.setState({
						isAppNameActive: false,
						isCoBranded: false,
						whiteLabelAppName: "",
						loginPageVersion: null,
						loginPageUrl: null,
						customerUrl: "",
						faviconImage: null,
						navbarImage: null,
						loginPageTagline: null,
						loginPageLogoWidth: null,
						onlineSupport: true,
						dedicatedSupportEmail: true,
						// sideMenuMainBgColor: null,
						// sideMenuSecondaryBgColor: null,
						// sideMenuEmphasisColor: null,
						isLoadingWhiteLabelData: false,
					});

					// return {
					// isCoBranded: false,
					// sideMenuMainBgColor: null,
					// sideMenuSecondaryBgColor: null,
					// sideMenuEmphasisColor: null,
					// };
				});
			/*
			.then(({isCoBranded, sideMenuMainBgColor, sideMenuSecondaryBgColor, sideMenuEmphasisColor}) => {
				
				if (!isCoBranded) {
					
					const root = document.querySelector(":root");
					if (root) {
						
						whiteLabelCustomItemNames.forEach(themeItemName => {
							if (whiteLabelCustomItemStyles[themeItemName]) {
								root.style.setProperty(themeItemName, whiteLabelCustomItemStyles[themeItemName]);
							}
						});
						
						[
							["--fk-side-menu-main-bg-color", sideMenuMainBgColor],
							["--fk-side-menu-secondary-bg-color", sideMenuSecondaryBgColor],
							["--fk-side-menu-emphasis-color", sideMenuEmphasisColor],
						].forEach(([themeItemName, themeItemValue]) => {
							if (themeItemValue && themeItemValue.length > 0) {
								root.style.setProperty(themeItemName, themeItemValue);
							}
						});
					}
				}
			});
			*/
		}

		//

		if (userToken === "" || userToken === null || userToken === "null") {
			this.setState({
				isLoadingUserToken: false,
				isLoadingClientSubscriptions: false,
				isLoadingUserActivation: false,
				isLoadingUserSiren: false,
				isLoadingUserProfile: false,
				isLoadingUserSessionFlag: false,
				isLoadingUserCompanySessionFlag: false,
			});
			// this.handleLogout(false);
			this.cleanUserData();

			this.writeCookie("etb_tk", "", 0);

			return;
		}

		//

		const payload = {
			token: userToken,
			appname: window.appName,
		};
		const authBaseUrl = config.auth.server[window.serverName];
		const url = authBaseUrl + "/auth/check";

		axios
			.post(url, payload, {headers: {appname: window.appName}})
			.then((response) => {
				// console.log(response);
				if (response && response.data.success && response.data.data) {
					this.writeCookie("etb_tk", userToken, 6 / 24);

					const userData = response.data.data;

					this.initUserAuthData(userData)
						.then(() => {
							return this.initUserAppData(userData);
						})
						.then(() => {
							// console.log(this.state);

							if (this.state.isAdminProfile) {
								// this.props.history.push('/admin/board');
								this.handleToAdminBoard();
							} else {
								// console.log(this.state.userAppAccess);

								if (this.state.userAppAccess.profile === null) {
									if (curPagePathName.indexOf("/subscribe") === -1) {
										this.logUserAction("Nav [App] to /subscribe");
										this.props.history.push("/subscribe", {
											paymentRequired: true,
										});
									}
								} else if (
									(this.state.userAppAccess.invited || this.state.userAppAccess.isSecondaryAccount) &&
									(this.state.isCompanyAccessExpired ||
										this.state.isCompanyAccessSuspended ||
										(this.state.userAppAccess.secondaryAccountEndAccessDate && new Date(this.state.userAppAccess.secondaryAccountEndAccessDate).getTime() < Date.now()))
								) {
									this.logUserAction("Nav [App] to /subscribe");
									this.props.history.push("/subscribe");
								}
							}
						});
				} else {
					console.log("SESSION EXPIRED");

					this.setState({
						isLoadingUserToken: false,
						isLoadingClientSubscriptions: false,
						isLoadingUserActivation: false,
						isLoadingUserSiren: false,
						isLoadingUserProfile: false,
						isLoadingUserSessionFlag: false,
						isLoadingUserCompanySessionFlag: false,
					});

					this.handleLogout(true);
				}
			})
			.catch(() => {
				console.log("SESSION EXPIRED");

				this.setState({
					isLoadingUserToken: false,
					isLoadingClientSubscriptions: false,
					isLoadingUserActivation: false,
					isLoadingUserSiren: false,
					isLoadingUserProfile: false,
					isLoadingUserSessionFlag: false,
					isLoadingUserCompanySessionFlag: false,
				});
				// console.log(error);

				this.handleLogout(true);
			});

		// Pour gérer vieux browsers et IE
		window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
		window.cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;
	}

	promisifiedSetState = (newState) => {
		return new Promise((resolve) => this.setState(newState, resolve));
	};

	/*
	handleOrientationChange = () => {
		
		setTimeout(() => {
			if ((typeof window.orientation !== "undefined" && (window.orientation === 0 || window.orientation === 180))
				|| (typeof window.screen.orientation !== "undefined" && window.screen.orientation.type === "portrait-primary")) {
				this.setState({showScreenWarning: true});
			} else {
				this.setState({showScreenWarning: false});
			}
		}, 50);
	}
	*/

	initUserAuthData = (userData) => {
		localStorage.setItem("token", userData.token);

		this.writeCookie("etb_tk", userData.token, 6 / 24);

		return this.promisifiedSetState({
			// userCode: userData.user,
			userCode: userData.usercode ? userData.usercode : userData.user, // migr auth // .user deprecated
			userToken: userData.token,
			userProfile: userData.profile,
			isSuperAdminProfile: userData.profile === "admin",
			isAdminProfile: userData.profile === "admin" || userData.profile === "user-admin",
			userActivation: userData.activated,
			userCreationDate: userData.created,
			isLoadingUserToken: false,
			isLoadingUserProfile: false,
			isLoadingUserActivation: false,
		});
	};

	initUserAppData = (userData) => {
		// 0. User identity

		localStorage.setItem("firstname", userData.firstname !== null ? userData.firstname : "");
		localStorage.setItem("lastname", userData.lastname !== null ? userData.lastname : "");
		localStorage.setItem("email", userData.email !== null ? userData.email : "");
		localStorage.setItem("firm", userData.firm !== null ? userData.firm : "");
		localStorage.setItem("job", userData.job !== null ? userData.job : "");
		localStorage.setItem("phone", userData.phone !== null ? userData.phone : "");

		this.setState({
			userFirstName: userData.firstname,
			userLastName: userData.lastname,
			userEmail: userData.email,
			userFirm: userData.firm,
			userJob: userData.job,
			userPhone: userData.phone,
			userType: userData.type,
		});

		if (this.state.isAdminProfile) {
			this.updateUserCompanyData({
				userCompanyName: "",
				countryCode: null,
				deptCode: null,
				companyLocalId: null,
				currencyCode: null,
				currencySymbol: null,
				companyLegalFormCode: null,
				companySubsidiaries: null,
				companyParentCompany: null,
			});
			this.setState({
				isLoadingClientSubscriptions: false,
				isLoadingUserSessionFlag: false,
				isLoadingUserSiren: false,
				isLoadingUserCompanySessionFlag: false,
			});
			return;
		} else {
			// Si pas admin

			// 1. User session flag
			// 2. User subscriptions

			return Promise.all([this.retrieveUserSessionFlag(), this.retrieveClientActiveSubscriptions()])
				.then((resps) => {
					if (resps) {
						const respClientSubscriptions = resps[1];
						// console.log(respClientSubscriptions);

						if (respClientSubscriptions && respClientSubscriptions.userAppAccess) {
							if (respClientSubscriptions.userAppAccess.featuresAccess && respClientSubscriptions.userAppAccess.featuresAccess["white-label"]) {
								// Si titulaire d'une marque blanche, on active le tawk
								this.initTawkApp();
							}

							const userAppProfile = respClientSubscriptions.userAppAccess.profile;

							if (userAppProfile === null) {
								this.setState({
									isLoadingUserSessionFlag: false,
									isLoadingClientSubscriptions: false,
									isLoadingUserSiren: false,
									isLoadingUserCompanySessionFlag: false,
								});
								return;
							} else {
								// console.log(respClientSubscriptions.userAppAccess);

								// Si pas dashboard on récupère le siren en cours

								// if (respClientSubscriptions.userAppAccess.featuresAccess && !respClientSubscriptions.userAppAccess.featuresAccess["company-dashboard"]) {

								// 3. User siren

								return this.retrieveUserSiren({
									resetConsolidatedView: true,
								}).then((userSiren) => {
									// console.log(userSiren);

									// 4. User company session flag

									return this.retrieveCompanySessionFlag(userSiren);
								});
								// }
								// else {

								// this.setState({
								// isLoadingUserSessionFlag: false,
								// isLoadingClientSubscriptions: false,
								// isLoadingUserSiren: false,
								// isLoadingUserCompanySessionFlag: false,
								// });
								// return;
								// }
							}
						} else {
							this.setState({
								isLoadingUserSessionFlag: false,
								isLoadingClientSubscriptions: false,
								isLoadingUserSiren: false,
								isLoadingUserCompanySessionFlag: false,
							});
							return;
						}
					} else {
						this.setState({
							isLoadingUserSessionFlag: false,
							isLoadingClientSubscriptions: false,
							isLoadingUserSiren: false,
							isLoadingUserCompanySessionFlag: false,
						});
						return;
					}
				})
				.catch((error) => {
					console.log(error);
					this.setState({
						isLoadingUserSessionFlag: false,
						isLoadingClientSubscriptions: false,
						isLoadingUserSiren: false,
						isLoadingUserCompanySessionFlag: false,
					});
					return;
				})
				.then(() => {
					// Tawk

					if (userData.firstname !== "" || userData.lastname !== "") {
						this.updateTawkId(userData.firstname + " " + userData.lastname, userData.email, userData.tawk);
					} else {
						this.updateTawkId(userData.email, userData.email, userData.tawk);
					}

					return;
				});
		}
	};

	updateUserCode = (usercode) => {
		return this.promisifiedSetState({userCode: usercode});
	};

	updateUserActivation = (userActivation) => {
		return this.promisifiedSetState({
			userActivation: userActivation,
			isLoadingUserActivation: false,
		});
	};

	updateUserProfile = (userProfile) => {
		return this.promisifiedSetState({
			userProfile: userProfile,
			isSuperAdminProfile: userProfile === "admin",
			isAdminProfile: userProfile === "admin" || userProfile === "user-admin",
			isLoadingUserProfile: false,
		});
	};

	retrieveClientActiveSubscriptions = () => {
		if (localStorage.getItem("token") === "") {
			this.setState({isLoadingClientSubscriptions: false});
			return null;
		}

		const apiBaseUrl = config.api.server[window.serverName];

		// const url = apiBaseUrl + config.api.root + '/user/subscriptions/active';
		var url = apiBaseUrl + config.api.root + "/user/subscriptions/active";
		if (this.state.isAdminProfile && this.state.adminMonitoredUserCode !== null) {
			url += "/" + this.state.adminMonitoredUserCode;
		}

		const reqConfig = {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		};

		return axios
			.get(url, reqConfig)
			.then((response) => {
				// console.log(response);

				if (response.data.success && response.data.data) {
					const data = response.data.data;
					const clientCurrentSubscriptionAccess = data.highestAccess;
					const clientCurrentOptions = data.options;
					// const clientCurrentCompaniesExtras = data.companiesExtraSubscriptions;

					var result = null;

					if (clientCurrentSubscriptionAccess !== null) {
						const subscriptionProfile = clientCurrentSubscriptionAccess.subscription_profile;

						result = {
							clientSubscriptions: data.activeSubscriptions,
							clientCurrentSubscription: data.currentSubscription,
							clientCurrentOptions: clientCurrentOptions,
							clientLastSubscription: data.lastSubscription,
							// clientCurrentCompaniesExtras: clientCurrentCompaniesExtras,
							// clientCurrentSubscriptionHost: (data.hostAccountData ? data.hostAccountData : null),
							userAppAccess: {
								profile: subscriptionProfile,
								// level: clientCurrentSubscriptionAccess.subscription_level,
								maxCompanies: clientCurrentSubscriptionAccess.max_companies,
								secondaryAccounts: data.secondaryAccounts,
								freeTrial: clientCurrentSubscriptionAccess.free_trial,
								invited: clientCurrentSubscriptionAccess.invited,
								isSecondaryAccount: clientCurrentSubscriptionAccess.secondary_account,
								// secondaryAccountEndAccessDate: data.secondaryAccountEndAccessDate,
								secondaryAccountEndAccessDate: clientCurrentSubscriptionAccess.secondaryAccountEndAccessDate,
								endDate: clientCurrentSubscriptionAccess.end_subscription_date,
								// companiesManagementAuthorised: data.companiesManagementAuthorised,
								companiesManagementAuthorised: clientCurrentSubscriptionAccess.companiesManagementAuthorised,
								featuresAccess: data.featuresAccess,
								canceled: clientCurrentSubscriptionAccess.canceled,
							},
						};

						return this.promisifiedSetState({
							...result,
							isLoadingClientSubscriptions: false,
						}).then(() => {
							return result;
						});
					} else {
						result = {
							clientSubscriptions: data.activeSubscriptions,
							clientCurrentSubscription: data.currentSubscription,
							clientCurrentOptions: clientCurrentOptions,
							clientLastSubscription: data.lastSubscription,
							// clientCurrentCompaniesExtras: clientCurrentCompaniesExtras,
							userAppAccess: {
								profile: null,
								// level: 1,
								maxCompanies: 0,
								secondaryAccounts: 0,
								freeTrial: null,
								invited: null,
								isSecondaryAccount: null,
								secondaryAccountEndAccessDate: null,
								endDate: null,
								companiesManagementAuthorised: null,
								featuresAccess: {},
							},
						};

						// On ne màj le state que si la réponse a changé (!== code 304)
						if (response.status === 200) {
							return this.promisifiedSetState({
								...result,
								isLoadingClientSubscriptions: false,
							}).then(() => {
								return result;
							});
						} else {
							this.setState({isLoadingClientSubscriptions: false});
							return result;
						}
					}
				} else {
					const result = {...this.initSubscriptionData};

					return this.promisifiedSetState({
						...result,
						isLoadingClientSubscriptions: false,
					}).then(() => {
						return result;
					});
				}
			})
			.catch((error) => {
				if (error.response && error.response.status === 498) {
					this.handleLogout(true);
				} else {
					console.log(error);
					if (error.response && error.response.status === 404) {
						return this.promisifiedSetState({
							isLoadingClientSubscriptions: false,
							...this.initSubscriptionData,
						}).then(() => {
							return null;
						});
					} else if (error.response && error.response.status === 503) {
						// pb connexion stripe
						return this.promisifiedSetState({
							isLoadingClientSubscriptions: false,
							...this.initSubscriptionData,
							isStripeConnectionDown: true,
						}).then(() => {
							return null;
						});
					} else {
						this.setState({isLoadingClientSubscriptions: false});
						return null;
					}
				}
			});
	};

	retrieveUserSiren = (options) => {
		// console.log("retrieveUserSiren");

		if (localStorage.getItem("token") === "") {
			this.setState({isLoadingUserSiren: false});
			return;
		}

		const apiBaseUrl = config.api.server[window.serverName];
		const reqConfig = {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		};

		var url = apiBaseUrl + config.api.root + "/user/company";
		if (this.state.isAdminProfile && this.state.adminMonitoredUserCode !== null) {
			url += "/" + this.state.adminMonitoredUserCode;
		}

		return axios
			.get(url, reqConfig)
			.then((response) => {
				// console.log(response);

				if (response && response.data.success && response.data.data) {
					const respData = response.data.data;
					const siren = respData.company;

					this.getCompanyChartOfAccounts(siren);

					const isCompanyOwner = !respData.companyOwner || respData.companyOwner === this.state.userCode;

					const newState = {
						userSiren: siren,
						accountsUuid: this.state.userAppAccess.featuresAccess && this.state.userAppAccess.featuresAccess["financials-load"] ? respData.id_liasse : null,
						isNewCompany: testIsNewCompany(siren),
						startAsNewCompany: respData.is_new_company,
						newCompanyFirstClosingYear: null,
						newCompanyClosingMonth: null,
						newCompanyStartYear: null,
						newCompanyStartMonth: null,
						isCompanyOwner: isCompanyOwner,
						isCompanyAccessLimited: respData.limited,
						isCompanyAccessExpired: respData.expired,
						companyAccessExpirationDate: respData.end_access_date,
						isCompanyAccessSuspended: respData.suspended,
						fecUuid: null,
						companyUseCase: respData.company_use_case,
						isLoadingUserSiren: false,
						companySubsidiaries: respData.subsidiaries,
						companyParentCompany: respData.parentCompany,
						companyGroupCompanies: respData.groupCompanies,
					};

					if (options && options.resetConsolidatedView) {
						newState.consolidatedView = false;
					}

					if (
						respData.is_new_company === true ||
						respData.id_liasse === null ||
						this.state.userAppAccess.featuresAccess === null ||
						!this.state.userAppAccess.featuresAccess["financials-load"]
					) {
						newState.newCompanyFirstClosingYear = respData.new_company_first_closing_year !== null ? respData.new_company_first_closing_year : null;
						newState.newCompanyClosingMonth = respData.new_company_closing_month !== null ? respData.new_company_closing_month : null;
						newState.newCompanyStartYear = respData.new_company_start_year !== null ? respData.new_company_start_year : null;
						newState.newCompanyStartMonth = respData.new_company_start_month !== null ? respData.new_company_start_month : null;

						if (
							newState.newCompanyFirstClosingYear !== null &&
							newState.newCompanyClosingMonth !== null &&
							newState.newCompanyStartYear !== null &&
							newState.newCompanyStartMonth !== null
						) {
							const userFirstPeriodLength =
								(newState.newCompanyFirstClosingYear - newState.newCompanyStartYear) * 12 + newState.newCompanyClosingMonth - newState.newCompanyStartMonth + 1;
							const firstPeriodNbYears = Math.ceil(userFirstPeriodLength / 12);
							newState.newCompanyFirstPeriodLength = userFirstPeriodLength;
							newState.newCompanyLongFirstFiscalYear = firstPeriodNbYears > 1;
						}
					}

					return Promise.all([
						axios.get(apiBaseUrl + config.api.root + "/company/" + siren, reqConfig),

						// (this.state.clientCurrentSubscription && this.state.clientCurrentSubscription.invited ? (
						!isCompanyOwner && !this.state.isAdminProfile
							? axios.get(
									apiBaseUrl +
										config.api.root +
										"/user/company/" +
										siren +
										"/contributor/" +
										// + (this.state.isAdminProfile && this.state.adminMonitoredUserCode !== null ? this.state.adminMonitoredUserCode : this.state.userCode)
										this.state.userCode +
										"/appaccess",
									reqConfig,
								)
							: Promise.resolve(null),
					])
						.then((resps2) => {
							var userCompanyName = null;

							if (resps2) {
								// console.log(resps2);

								if (resps2[0] && resps2[0].data.success && resps2[0].data.data) {
									const respData2 = resps2[0].data.data;
									// console.log(respData2);

									if (testIsNewCompany(siren) && typeof respData2.company_name !== "undefined") {
										userCompanyName = respData2.company_name;
									} else if (typeof respData2.nom !== "undefined") {
										userCompanyName = respData2.nom;
									}

									newState.countryCode = respData2.country_code;
									newState.deptCode = respData2.dept_code;

									newState.currencyCode = respData2.currency_code;
									newState.currencySymbol = respData2.currency_symbol !== null ? respData2.currency_symbol : respData2.currency_code;
									newState.currencyText = respData2.currency_code === "EUR" ? "euros" : newState.currencySymbol;

									newState.companyLocalId = respData2.company_local_id;

									newState.companyLegalFormCode = respData2.code_categorie_juridique;
									// newState.isMicroCompany = respData2.is_micro_company;
									// newState.microCompanyType = respData2.micro_company_type;
								}

								if (resps2[1] && resps2[1].data.success && resps2[1].data.data) {
									if (this.state.userAppAccess.featuresAccess) {
										newState.userAppAccess = {
											...this.state.userAppAccess,
											featuresAccess: {
												...this.state.userAppAccess.featuresAccess,
											},
										};

										const contributorFeatures = resps2[1].data.data;

										Object.keys(contributorFeatures).forEach((featureKey) => {
											newState.userAppAccess.featuresAccess[featureKey] =
												typeof newState.userAppAccess.featuresAccess[featureKey] !== "undefined"
													? contributorFeatures[featureKey]
														? newState.userAppAccess.featuresAccess[featureKey]
														: false
													: contributorFeatures[featureKey];
										});
									}
								}
							}

							newState.userCompanyName = userCompanyName;
						})
						.then(() => {
							return this.promisifiedSetState(newState).then(() => {
								return siren;
							});
						})
						.catch((error) => {
							if (error.response && error.response.status === 498) {
								this.handleLogout(true);
							} else {
								console.log(error);
								return this.promisifiedSetState(newState).then(() => {
									return siren;
								});
							}
						});
				} else {
					this.setState({
						userSiren: null,
						userCompanyName: null,
						isCompanyOwner: true,
						isCompanyAccessLimited: false,
						isCompanyAccessExpired: false,
						companyAccessExpirationDate: null,
						isCompanyAccessSuspended: false,
						accountsUuid: null,
						isNewCompany: null,
						startAsNewCompany: null,
						newCompanyFirstClosingYear: null,
						newCompanyClosingMonth: null,
						newCompanyFirstPeriodLength: null,
						newCompanyLongFirstFiscalYear: null,
						countryCode: (typeof defaultCompanyParameters[window.appName] !== "undefined" ? defaultCompanyParameters[window.appName] : defaultCompanyParameters["default"])
							.countryCode,
						deptCode: null,
						currencyCode: (typeof defaultCompanyParameters[window.appName] !== "undefined" ? defaultCompanyParameters[window.appName] : defaultCompanyParameters["default"])
							.currencyCode,
						currencySymbol: (typeof defaultCompanyParameters[window.appName] !== "undefined" ? defaultCompanyParameters[window.appName] : defaultCompanyParameters["default"])
							.currencySymbol,
						currencyText: (typeof defaultCompanyParameters[window.appName] !== "undefined" ? defaultCompanyParameters[window.appName] : defaultCompanyParameters["default"])
							.currencyText,
						companyLocalId: null,
						companyLegalFormCode: null,
						companySubsidiaries: null,
						companyParentCompany: null,
						fecUuid: null,
						companyUseCase: null,
						isLoadingUserSiren: false,
					});
					return null;
				}
			})
			.catch((error) => {
				if (error.response && error.response.status === 498) {
					this.handleLogout(true);
				} else {
					console.log(error);
					this.setState({isLoadingUserSiren: false});
					if (error.response && error.response.status === 404) {
						if (this.state.userAppAccess.profile === null || this.state.userAppAccess.maxCompanies === 0) {
							this.props.history.push("/subscribe");
							// } else if (this.state.userAppAccess.maxCompanies > 1 || this.state.userAppAccess.maxCompanies === -1) {
						} else if (this.state.userAppAccess.featuresAccess && this.state.userAppAccess.featuresAccess["company-dashboard"]) {
							this.props.history.push("/companies/dashboard");
						} else if (this.state.userAppAccess.maxCompanies === 1) {
							this.props.history.push("/company");
						} else if (this.state.isAdminProfile) {
							this.props.history.push("/admin/board");
						} else {
							this.props.history.push("/login");
						}
					}
					return null;
				}
			});
	};

	updateUserSiren = ({siren, companyOwner, isCompanyAccessLimited, isCompanyAccessExpired, companyAccessExpirationDate, isCompanyAccessSuspended}) => {
		// console.log("updateUserSiren", siren);

		const isCompanyOwner = !companyOwner || companyOwner === this.state.userCode;

		const newState = {
			userSiren: siren,
			isNewCompany: siren ? testIsNewCompany(siren) : true,
			isLoadingUserSiren: false,
			// isCompanyAccessLimited: false,
			// isCompanyAccessExpired: false,
			// companyAccessExpirationDate: null,
			// isCompanyAccessSuspended: false,
			isCompanyOwner: isCompanyOwner,
			isCompanyAccessLimited: typeof isCompanyAccessLimited !== "undefined" ? isCompanyAccessLimited : false,
			isCompanyAccessExpired: typeof isCompanyAccessExpired !== "undefined" ? isCompanyAccessExpired : false,
			companyAccessExpirationDate: typeof companyAccessExpirationDate !== "undefined" ? companyAccessExpirationDate : null,
			isCompanyAccessSuspended: typeof isCompanyAccessSuspended !== "undefined" ? isCompanyAccessSuspended : false,
			fecUuid: null,
			consolidatedView: false,
		};

		this.getCompanyChartOfAccounts(siren);

		// return (siren !== null && this.state.clientCurrentSubscription && this.state.clientCurrentSubscription.invited ? (
		return (
			siren !== null && !isCompanyOwner && !this.state.isAdminProfile
				? axios
						.get(
							config.api.server[window.serverName] +
								config.api.root +
								"/user/company/" +
								siren +
								"/contributor/" +
								// + (this.state.isAdminProfile && this.state.adminMonitoredUserCode !== null ? this.state.adminMonitoredUserCode : this.state.userCode)
								this.state.userCode +
								"/appaccess",
							{
								headers: {
									"x-access-token": this.state.userToken,
									appname: window.appName,
								},
							},
						)
						.then((resp) => {
							// console.log(resp);

							if (resp && resp.data.success && resp.data.data) {
								if (this.state.userAppAccess.featuresAccess) {
									newState.userAppAccess = {
										...this.state.userAppAccess,
										featuresAccess: {
											...this.state.userAppAccess.featuresAccess,
										},
									};

									const contributorFeatures = resp.data.data;

									Object.keys(contributorFeatures).forEach((featureKey) => {
										newState.userAppAccess.featuresAccess[featureKey] =
											typeof newState.userAppAccess.featuresAccess[featureKey] !== "undefined"
												? contributorFeatures[featureKey]
													? newState.userAppAccess.featuresAccess[featureKey]
													: false
												: contributorFeatures[featureKey];
									});
								}
							}
						})
				: Promise.resolve(null)
		).then(() => this.promisifiedSetState(newState));
	};

	updateUserCompanyDates = ({
		startAsNewCompany,
		newCompanyFirstClosingYear,
		newCompanyClosingMonth,
		// newCompanyLongFirstFiscalYear,
		newCompanyStartYear,
		newCompanyStartMonth,
	}) => {
		const newState = {
			startAsNewCompany: this.state.startAsNewCompany,
			newCompanyFirstClosingYear: this.state.newCompanyFirstClosingYear,
			newCompanyClosingMonth: this.state.newCompanyClosingMonth,
			newCompanyStartYear: this.state.newCompanyStartYear,
			newCompanyStartMonth: this.state.newCompanyStartMonth,
		};

		if (typeof startAsNewCompany !== "undefined") {
			newState.startAsNewCompany = startAsNewCompany;
		}

		if (typeof newCompanyFirstClosingYear !== "undefined") {
			newState.newCompanyFirstClosingYear = newCompanyFirstClosingYear;
		}

		if (typeof newCompanyClosingMonth !== "undefined") {
			newState.newCompanyClosingMonth = newCompanyClosingMonth;
		}

		// if (typeof newCompanyLongFirstFiscalYear !== "undefined") {
		// newState.newCompanyLongFirstFiscalYear = newCompanyLongFirstFiscalYear;
		// }

		if (typeof newCompanyStartYear !== "undefined") {
			newState.newCompanyStartYear = newCompanyStartYear;
		}

		if (typeof newCompanyStartMonth !== "undefined") {
			newState.newCompanyStartMonth = newCompanyStartMonth;
		}

		const userFirstPeriodLength = (newState.newCompanyFirstClosingYear - newState.newCompanyStartYear) * 12 + newState.newCompanyClosingMonth - newState.newCompanyStartMonth + 1;
		const firstPeriodNbYears = Math.ceil(userFirstPeriodLength / 12);
		newState.newCompanyFirstPeriodLength = userFirstPeriodLength;
		newState.newCompanyLongFirstFiscalYear = firstPeriodNbYears > 1;

		return this.promisifiedSetState(newState);
	};

	updateUserCompanyData = ({
		userCompanyName,
		countryCode,
		deptCode,
		companyLocalId,
		currencyCode,
		currencySymbol,
		companyLegalFormCode,
		companySubsidiaries,
		companyParentCompany,
		companyOwner,
		isCompanyAccessLimited,
		isCompanyAccessExpired,
		companyAccessExpirationDate,
		isCompanyAccessSuspended,
	}) => {
		localStorage.setItem("company", typeof userCompanyName !== "undefined" && userCompanyName !== null ? userCompanyName : "");

		const newState = {};

		if (typeof userCompanyName !== "undefined") {
			newState.userCompanyName = userCompanyName;
		}

		if (typeof countryCode !== "undefined") {
			if (countryCode !== null) {
				newState.countryCode = countryCode;
			} else {
				if (typeof defaultCompanyParameters[window.appName] !== "undefined") {
					newState.countryCode = defaultCompanyParameters[window.appName].countryCode;
				} else {
					newState.countryCode = defaultCompanyParameters["default"].countryCode;
				}
			}
		}

		if (typeof deptCode !== "undefined") {
			newState.deptCode = deptCode;
		}

		if (typeof companyLocalId !== "undefined") {
			newState.companyLocalId = companyLocalId;
		}

		if (typeof currencyCode !== "undefined") {
			if (currencyCode !== null) {
				newState.currencyCode = currencyCode;
			} else {
				if (typeof defaultCompanyParameters[window.appName] !== "undefined") {
					newState.currencyCode = defaultCompanyParameters[window.appName].currencyCode;
				} else {
					newState.currencyCode = defaultCompanyParameters["default"].currencyCode;
				}
			}

			if (currencySymbol !== null) {
				newState.currencySymbol = currencySymbol;
			} else {
				if (typeof defaultCompanyParameters[window.appName] !== "undefined") {
					newState.currencySymbol = defaultCompanyParameters[window.appName].currencySymbol;
				} else {
					newState.currencySymbol = defaultCompanyParameters["default"].currencySymbol;
				}
			}

			newState.currencyText = newState.currencyCode === "EUR" ? "euros" : newState.currencySymbol;
		}

		if (typeof companyLegalFormCode !== "undefined") {
			newState.companyLegalFormCode = companyLegalFormCode;
		}

		if (typeof companySubsidiaries !== "undefined") {
			newState.companySubsidiaries = companySubsidiaries;
		}

		if (typeof companyParentCompany !== "undefined") {
			newState.companyParentCompany = companyParentCompany;
		}

		if (typeof companyOwner !== "undefined") {
			newState.isCompanyOwner = companyOwner === this.state.userCode;
		}

		if (typeof isCompanyAccessLimited !== "undefined") {
			newState.isCompanyAccessLimited = isCompanyAccessLimited;
		}

		if (typeof isCompanyAccessExpired !== "undefined") {
			newState.isCompanyAccessExpired = isCompanyAccessExpired;
		}

		if (typeof companyAccessExpirationDate !== "undefined") {
			newState.companyAccessExpirationDate = companyAccessExpirationDate;
		}

		if (typeof isCompanyAccessSuspended !== "undefined") {
			newState.isCompanyAccessSuspended = isCompanyAccessSuspended;
		}

		return this.promisifiedSetState(newState);
	};

	/*
  updateUserCompany = (userCompanyName) => {
    localStorage.setItem('company', (userCompanyName !== null ? userCompanyName : ''));
    return this.promisifiedSetState({
      userCompanyName: userCompanyName
    });
  }

  updateCountry = (countryCode, deptCode) => {
    return this.promisifiedSetState({
      countryCode: (typeof countryCode !== "undefined" ? countryCode : (
				(typeof defaultCompanyParameters[window.appName] !== "undefined" ? defaultCompanyParameters[window.appName] : defaultCompanyParameters["default"]).countryCode
			)),
			deptCode: (typeof deptCode !== "undefined" ? deptCode : null),
    });
  }
	
  updateCompanyLocalId = (companyId) => {
    return this.promisifiedSetState({
      companyLocalId: companyId,
    });
  }
	
  updateCurrency = (currencyCode, currencySymbol) => {
		
		const currencyCode2 = (typeof currencyCode !== "undefined" ? currencyCode : (
			(typeof defaultCompanyParameters[window.appName] !== "undefined" ? defaultCompanyParameters[window.appName] : defaultCompanyParameters["default"]).currencyCode
		));
		const currencySymbol2 = (typeof currencySymbol !== "undefined" && currencySymbol !== null ? currencySymbol : (
			typeof currencyCode !== "undefined" ? currencyCode : (
				(typeof defaultCompanyParameters[window.appName] !== "undefined" ? defaultCompanyParameters[window.appName] : defaultCompanyParameters["default"]).currencySymbol
			)
		));
		
    return this.promisifiedSetState({
      currencyCode: currencyCode2,
      currencySymbol: currencySymbol2,
      currencyText: (currencyCode2 === "EUR" ? "euros" : currencySymbol2),
    });
  }
	
	updateCompanyLegalForm = (legalForm) => {
    return this.promisifiedSetState({
			companyLegalFormCode: legalForm
		});
  }
	*/

	updateUserToken = (userToken) => {
		localStorage.setItem("token", userToken !== null ? userToken : "");

		if (userToken === null) {
			this.writeCookie("etb_tk", "", 0);
		} else {
			this.writeCookie("etb_tk", userToken, 6 / 24);
		}

		return this.promisifiedSetState({
			userToken: userToken,
			isLoadingUserToken: false,
		});
	};

	updateUserSessionFlag = (newSessionFlag) => {
		// console.log("updateUserSessionFlag", newSessionFlag);

		if (!this.state.isAdminProfile && newSessionFlag !== null && newSessionFlag !== this.state.userUserSessionFlag) {
			const payload = {
				sessionFlag: newSessionFlag,
			};
			axios
				.put(config.api.server[window.serverName] + config.api.root + "/user/usersession", payload, {
					headers: {
						"x-access-token": localStorage.getItem("token"),
						appname: window.appName,
					},
				})
				.catch((error) => {
					if (error.response && error.response.status === 498) {
						this.handleLogout(true);
					} else {
						console.log(error);
					}
				});
		}

		return this.promisifiedSetState({userUserSessionFlag: newSessionFlag});
	};

	retrieveUserSessionFlag = () => {
		if (localStorage.getItem("token") === "") {
			this.setState({isLoadingUserSessionFlag: false});
			return null;
		}

		const apiBaseUrl = config.api.server[window.serverName];

		const url = apiBaseUrl + config.api.root + "/user/usersession";

		return axios
			.get(url, {
				headers: {
					"x-access-token": localStorage.getItem("token"),
					appname: window.appName,
				},
			})
			.then((response) => {
				// console.log(response);

				if (response && response.data.success) {
					const flag = Number(response.data.data);
					this.setState({
						isLoadingUserSessionFlag: false,
						userUserSessionFlag: flag,
					});
					return flag;
				} else {
					this.setState({isLoadingUserSessionFlag: false});
					return null;
				}
			})
			.catch((error) => {
				if (error.response && error.response.status === 498) {
					this.handleLogout(true);
				} else {
					console.log(error);
					if (error.response && error.response.status === 404) {
						this.setState({isLoadingUserSessionFlag: false});
					}
					return null;
				}
			});
	};

	updateCompanySessionFlag = (newSessionFlag) => {
		// console.log(this.state.userCompanySessionFlag, newSessionFlag);

		if (!this.state.isAdminProfile && newSessionFlag !== null && this.state.userSiren !== null && newSessionFlag !== this.state.userCompanySessionFlag) {
			const payload = {
				sessionFlag: newSessionFlag,
				siren: this.state.userSiren,
			};
			axios
				.put(config.api.server[window.serverName] + config.api.root + "/user/companysession", payload, {
					headers: {
						"x-access-token": localStorage.getItem("token"),
						appname: window.appName,
					},
				})
				.catch((error) => {
					if (error.response && error.response.status === 498) {
						this.handleLogout(true);
					} else {
						console.log(error);
					}
				});
		}

		return this.promisifiedSetState({userCompanySessionFlag: newSessionFlag});
	};

	retrieveCompanySessionFlag = (siren) => {
		if (localStorage.getItem("token") === "") {
			this.setState({isLoadingUserCompanySessionFlag: false});
			return null;
		}

		const apiBaseUrl = config.api.server[window.serverName];

		var url = apiBaseUrl + config.api.root + "/user/companysession/" + siren;

		if (this.state.isAdminProfile && this.state.adminMonitoredUserCode !== null) {
			url += "/" + this.state.adminMonitoredUserCode;
		}

		return axios
			.get(url, {
				headers: {
					"x-access-token": localStorage.getItem("token"),
					appname: window.appName,
				},
			})
			.then((response) => {
				// console.log(response);

				if (response.data.success) {
					const flag = Number(response.data.data);

					return this.promisifiedSetState({
						isLoadingUserCompanySessionFlag: false,
						userCompanySessionFlag: flag,
					}).then(() => {
						return flag;
					});
				} else {
					this.setState({isLoadingUserCompanySessionFlag: false});
					return null;
				}
			})
			.catch((error) => {
				if (error.response && error.response.status === 498) {
					this.handleLogout(true);
				} else {
					console.log(error);
					console.log(error.response);
					if (error.response && error.response.status === 404) {
						this.setState({isLoadingUserCompanySessionFlag: false});
					}
					return null;
				}
			});
	};

	updateUserIdentity = (userIdentityData) => {
		const {userFirstName, userLastName, userEmail, userFirm, userJob, userPhone} = userIdentityData;

		if (typeof userFirstName !== "undefined") localStorage.setItem("firstname", userFirstName !== null ? userFirstName : "");
		if (typeof userLastName !== "undefined") localStorage.setItem("lastname", userLastName !== null ? userLastName : "");
		if (typeof userEmail !== "undefined") localStorage.setItem("email", userEmail !== null ? userEmail : "");
		if (typeof userFirm !== "undefined") localStorage.setItem("firm", userFirm !== null ? userFirm : "");
		if (typeof userJob !== "undefined") localStorage.setItem("job", userJob !== null ? userJob : "");
		if (typeof userPhone !== "undefined") localStorage.setItem("phone", userPhone !== null ? userPhone : "");

		return this.promisifiedSetState(userIdentityData);
	};

	saveUserIdentityData = (userIdentityData) => {
		const {userFirstName, userLastName, userEmail, userFirm, userJob, userPhone} = userIdentityData;

		if (config.gtmTracking && typeof window.dataLayer !== "undefined") {
			window.dataLayer.push({
				event: "save_personal_data",
				user_status: "logged_in",
				content_category: "use_app_action",
				content_type: "save_personal_data",
				email: typeof userEmail !== "undefined" ? userEmail : this.state.userEmail,
				lastname: typeof userLastName !== "undefined" ? userLastName : this.state.userLastName,
				firstname: typeof userFirstName !== "undefined" ? userFirstName : this.state.userFirstName,
				job: typeof userJob !== "undefined" ? userJob : this.state.userJob,
				phone: typeof userPhone !== "undefined" ? userPhone : this.state.userPhone,
			});
		}

		crmLogSavePersonalData({
			firstName: userFirstName,
			lastName: userLastName,
			company: userFirm,
			job: userJob,
			phone: userPhone,
		});

		const authBaseUrl = config.auth.server[window.serverName];

		const authUserData = {};
		if (typeof userLastName !== "undefined") authUserData.lastname = userLastName;
		if (typeof userFirstName !== "undefined") authUserData.firstname = userFirstName;
		if (typeof userJob !== "undefined") authUserData.job = userJob;
		if (typeof userPhone !== "undefined") authUserData.phone = userPhone;
		if (typeof userFirm !== "undefined") authUserData.firm = userFirm;

		axios
			.put(
				authBaseUrl + config.auth.uri + "/userdata",
				{userdata: authUserData},
				{
					headers: {
						"x-access-token": localStorage.getItem("token"),
						appname: window.appName,
					},
				},
			)
			.then((resp) => {
				// console.log(resp);

				if (resp && resp.data.success && resp.data.data) {
					const savedUserData = resp.data.data;

					this.initUserAuthData(savedUserData);

					this.updateUserIdentity({
						userFirstName: savedUserData.firstname,
						userLastName: savedUserData.lastname,
						userEmail: savedUserData.email,
						userFirm: savedUserData.firm,
						userJob: savedUserData.job,
						userPhone: savedUserData.phone,
						userType: savedUserData.type,
						userCreationDate: savedUserData.created,
					});

					this.updateTawkId(savedUserData.firstname + " " + savedUserData.lastname, this.state.userEmail, savedUserData.tawk);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	initTawkApp = () => {
		if (process.env.NODE_ENV === "production") {
			//////////// CHAT /////////////////
			window.Tawk_API = window.Tawk_API || {};
			window.Tawk_LoadStart = new Date();
			if (
				localStorage.getItem("firstname") !== null &&
				localStorage.getItem("firstname") !== "" &&
				localStorage.getItem("lastname") !== null &&
				localStorage.getItem("lastname") !== "" &&
				localStorage.getItem("email") !== null &&
				localStorage.getItem("email") !== "" &&
				localStorage.getItem("tawk") !== null &&
				localStorage.getItem("tawk") !== ""
			) {
				window.Tawk_API.visitor = {
					name: localStorage.getItem("firstname") + " " + localStorage.getItem("lastname"),
					email: localStorage.getItem("email"),
					hash: localStorage.getItem("tawk"),
				};
			}

			window.Tawk_API.onOfflineSubmit = function (data) {
				if (data) {
					const {email, name, phone} = data;
					if (email && phone) {
						crmLogHelpDeskData({
							email: email,
							name: name,
							phone: phone,
						});
					}
				}
			};

			(function () {
				var s1 = document.createElement("script"),
					s0 = document.getElementsByTagName("script")[0];
				s1.async = true;
				switch (window.appName) {
					case "ysibiz":
						s1.src = "https://embed.tawk.to/5ec08612967ae56c521a7c04/default";
						break;
					case "evaltonbiz":
					default:
						s1.src = "https://embed.tawk.to/59e61fea4854b82732ff61a7/default";
						break;
				}
				s1.charset = "UTF-8";
				s1.setAttribute("crossorigin", "*");
				s0.parentNode.insertBefore(s1, s0);
			})();
			////////////////////////////////////////
		}
	};

	updateTawkId = (name, email, hash) => {
		//console.log("updateTawkId / " + name + " / " + email);
		//console.log(window.Tawk_API);
		localStorage.setItem("tawk", hash !== null ? hash : "");

		if (typeof window.Tawk_API !== "undefined" && typeof window.Tawk_API.setAttributes !== "undefined") {
			try {
				window.Tawk_API.setAttributes({
					name: name,
					email: email,
					hash: hash,
				});
			} catch (error) {
				console.log(error);
			}
		}
	};

	updateActiveAccounts = (accountsUuid) => {
		if (accountsUuid !== null) {
			return this.promisifiedSetState({
				accountsUuid: accountsUuid,
				// isNewCompany: false,
				startAsNewCompany: false,
				newCompanyYear: null,
				newCompanyClosingMonth: null,
				newCompanyFirstPeriodLength: null,
				newCompanyLongFirstFiscalYear: null,
			});
		} else {
			return this.promisifiedSetState({
				accountsUuid: null,
			});
		}
	};

	updateActiveFec = (fecUuid) => {
		this.setState({fecUuid: fecUuid});
	};

	updateCompanyUseCase = (companyUseCase) => {
		this.setState({companyUseCase: companyUseCase});
	};

	getCompanyChartOfAccounts = (siren) => {
		siren = typeof siren !== "undefined" ? siren : this.state.userSiren;

		if (siren) {
			const apiBaseUrl = config.api.server[window.serverName];

			const reqConfig = {
				headers: {
					"x-access-token": localStorage.getItem("token"),
					appname: window.appName,
				},
			};

			var urlChartOfAccounts = apiBaseUrl + config.api.root + "/financials/chartofaccounts/" + siren;
			if (this.state.isAdminProfile) {
				urlChartOfAccounts += "/" + this.state.adminMonitoredUserCode;
			}

			if (this.state.consolidatedView && this.state.companyGroupCompanies) {
				urlChartOfAccounts += "?consolidated=true&";

				this.state.companyGroupCompanies.forEach(({siren}) => {
					urlChartOfAccounts += "siren=" + siren + "&";
				});
			}

			axios
				.get(urlChartOfAccounts, reqConfig)
				.then((chartOfAccountsResp) => {
					if (chartOfAccountsResp && chartOfAccountsResp.data.success && chartOfAccountsResp.data.data) {
						const chartOfAccounts = {};
						chartOfAccountsResp.data.data.forEach(({compte_num, compte_lib}) => {
							chartOfAccounts[compte_num] = compte_lib;
						});
						this.setState({chartOfAccounts: chartOfAccounts});
					}
				})
				.catch((error) => {
					if (error.response && error.response.status === 498) {
						this.handleLogout(true);
					} else {
						console.log(error);
					}
				});
		} else {
			this.setState({chartOfAccounts: null});
		}
	};

	handleCreateAccountNumber = (accountNumber, label) => {
		
		if (!label) {
			
			const accountNumberLength = accountNumber.length;

			for (let i = accountNumberLength; i > 0; i--) {
				let candidateAccountNumber = accountNumber.substring(0, i);

				if (typeof this.state.chartOfAccounts[candidateAccountNumber] !== "undefined") {
					label = this.state.chartOfAccounts[candidateAccountNumber];
					break;
				}
			}
		}
		
		const chartOfAccounts = {
			...this.state.chartOfAccounts,
			// [accountNumber]: this.state.chartOfAccounts[candidateAccountNumber],
			[accountNumber]: label,
		};

		this.setState({chartOfAccounts: chartOfAccounts});

		// Save

		const apiBaseUrl = config.api.server[window.serverName];
		const reqConfig = {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		};
		const url = apiBaseUrl + config.api.root + "/financials/chartofaccounts/" + this.state.userSiren;
		const payload = {
			accountNumber,
			// accountLabel: this.state.chartOfAccounts[candidateAccountNumber],
			accountLabel: label,
		};

		axios.post(url, payload, reqConfig).catch((err) => {
			console.log(err);
		});
	};

	logUserAction = (action, token) => {
		// console.log("logUserAction", action)

		token = token ? token : this.state.userToken;

		if (token === "" || token === null || token === "null") {
			return;
		}

		if (action.indexOf("Signup") > -1 || action.indexOf("Login") > -1 || action.indexOf("Logout") > -1) {
			action +=
				" / " +
				this.state.browserInfo.os +
				" - " +
				this.state.browserInfo.name +
				"." +
				this.state.browserInfo.version +
				" / " +
				this.state.browserInfo.platform +
				" / " +
				window.screen.width +
				"x" +
				window.screen.height +
				(this.touchDevice ? " / Touch" : "");
		}

		// console.log(action);
		const payload = {
			action: action,
		};

		const apiBaseUrl = config.api.server[window.serverName];

		const url = apiBaseUrl + config.api.root + "/user/action";

		axios
			.post(url, payload, {
				headers: {"x-access-token": token, appname: window.appName},
			})
			.catch((error) => {
				if (error.response && error.response.status === 498) {
					this.handleLogout(true);
				} else {
					console.log(error);
				}
			});
	};

	/*
	navTo = (event) => {
		if (event) event.preventDefault();
		this.props.history.push(event.currentTarget.getAttribute('href'));
	}
	*/

	handleToWelcome = () => {
		this.logUserAction("Nav [Menu] to /profile");
		this.props.history.push("/profile");
	};

	cleanUserData = () => {
		this.updateUserSiren({siren: null}).then(() => {
			this.updateUserCompanyDates({
				startAsNewCompany: null,
				newCompanyFirstClosingYear: null,
				newCompanyClosingMonth: null,
				newCompanyStartYear: null,
				newCompanyStartMonth: null,
			});

			this.updateUserToken(null);

			this.updateUserIdentity({
				userFirstName: "",
				userLastName: "",
				userEmail: "",
				userFirm: "",
				userJob: "",
				userPhone: "",
				userType: "",
				userCreationDate: null,
			});

			this.updateUserCompanyData({
				userCompanyName: "",
				countryCode: null,
				deptCode: null,
				companyLocalId: null,
				currencyCode: null,
				currencySymbol: null,
				companyLegalFormCode: null,
				companySubsidiaries: null,
				companyParentCompany: null,
			});
			this.updateActiveAccounts(null);
			this.updateActiveFec(null);
			this.updateUserSessionFlag(null);
			this.updateCompanySessionFlag(null);
			this.updateCompanyUseCase(null);
			this.adminMonitorUser(null);
			this.updateTawkId("", "", "");
		});
	};

	handleClickLogout = () => {
		this.handleLogout();
	};

	handleLogout = (expired) => {
		expired = typeof expired === "undefined" ? false : expired;

		this.logUserAction("Logout");

		this.props.history.push("/login", {
			expired: expired,
		});

		this.cleanUserData();
	};

	handleToAdminBoard = () => {
		const authBaseUrl = config.auth.server[window.serverName];
		const authUri = config.auth.uri;

		const reqConfig = {
			headers: {
				"x-access-token": localStorage.getItem("token"),
				appname: window.appName,
			},
		};

		axios
			.get(authBaseUrl + authUri + "/admin/user/" + window.appName + "/" + this.state.userCode, reqConfig)
			.then((resp) => {
				if (resp.data.success) {
					//console.log(resp.data.data);

					const user = resp.data.data;
					// this.updateUserIdentity(user.firstname, user.lastname, user.email, user.firm, user.job, user.phone, user.type, user.created);
					this.updateUserIdentity({
						userFirstName: user.firstname,
						userLastName: user.lastname,
						userEmail: user.email,
						userFirm: user.firm,
						userJob: user.job,
						userPhone: user.phone,
						userType: user.type,
						userCreationDate: user.created,
					});
				}
			})
			.catch((error) => {
				if (error.response && error.response.status === 498) {
					this.handleLogout(true);
				} else {
					console.log(error);
				}
			});

		this.updateUserSiren({siren: null}).then(() => {
			this.updateUserCompanyDates({
				startAsNewCompany: null,
				newCompanyFirstClosingYear: null,
				newCompanyClosingMonth: null,
				newCompanyStartYear: null,
				newCompanyStartMonth: null,
			});

			this.updateUserCompanyData({
				userCompanyName: "",
				countryCode: null,
				deptCode: null,
				companyLocalId: null,
				currencyCode: null,
				currencySymbol: null,
				companyLegalFormCode: null,
				companySubsidiaries: null,
				companyParentCompany: null,
			});
			this.updateActiveAccounts(null);
			this.updateActiveFec(null);
			this.updateCompanySessionFlag(null);
			this.updateCompanyUseCase(null);
			this.adminMonitorUser(null);

			this.logUserAction("Nav [Menu] to /admin/board");
			this.props.history.push("/admin/board");
		});
	};

	handleToDashboard = () => {
		document.body.click();

		const apiBaseUrl = config.api.server[window.serverName];

		axios
			.put(
				apiBaseUrl + config.api.root + "/user/companies/null",
				{},
				{
					headers: {
						"x-access-token": localStorage.getItem("token"),
						appname: window.appName,
					},
				},
			)
			.then((resp) => {
				if (resp.data.success) {
					this.updateUserSiren({siren: null}).then(() => {
						this.props.history.push("/companies/dashboard");

						this.updateUserCompanyDates({
							startAsNewCompany: null,
							newCompanyFirstClosingYear: null,
							newCompanyClosingMonth: null,
							newCompanyStartYear: null,
							newCompanyStartMonth: null,
						});

						this.updateUserCompanyData({
							userCompanyName: "",
							countryCode: null,
							deptCode: null,
							companyLocalId: null,
							currencyCode: null,
							currencySymbol: null,
							companyLegalFormCode: null,
							companySubsidiaries: null,
							companyParentCompany: null,
						});
						this.updateActiveAccounts(null);
						this.updateActiveFec(null);
						this.updateCompanySessionFlag(null);
						this.updateCompanyUseCase(null);
						this.logUserAction("Nav [Menu] to /companies/dashboard");
					});
				}
			})
			.catch((error) => {
				if (error.response && error.response.status === 498) {
					this.handleLogout(true);
				} else {
					console.log(error);
				}
			});
	};

	handleToProject = () => {
		if (
			(this.state.userAppAccess.invited || this.state.userAppAccess.isSecondaryAccount) &&
			(this.state.isCompanyAccessExpired ||
				this.state.isCompanyAccessSuspended ||
				(this.state.userAppAccess.secondaryAccountEndAccessDate && new Date(this.state.userAppAccess.secondaryAccountEndAccessDate).getTime() < Date.now()))
		) {
			this.logUserAction("Nav [App] to /subscribe");
			this.props.history.push("/subscribe");
		} else {
			const path = sessionParameters.getHighestSessionPath(this.state.userCompanySessionFlag, this.state.userAppAccess);
			this.logUserAction("Nav [Menu] to " + path);
			this.props.history.push(path);
		}
	};

	handleToCompany = () => {
		if (
			(this.state.userAppAccess.invited || this.state.userAppAccess.isSecondaryAccount) &&
			(this.state.isCompanyAccessExpired ||
				this.state.isCompanyAccessSuspended ||
				(this.state.userAppAccess.secondaryAccountEndAccessDate && new Date(this.state.userAppAccess.secondaryAccountEndAccessDate).getTime() < Date.now()))
		) {
			this.logUserAction("Nav [App] to /subscribe");
			this.props.history.push("/subscribe");
		} else {
			this.updateCompanySessionFlag(sessionParameters.companySessionFlags["/company"]);
			this.logUserAction("Nav [Nav] to /company");
			this.props.history.push("/company");
		}
	};

	handleToMarketData = () => {
		document.body.click();
		this.logUserAction("Nav [Menu] to /market");
		this.props.history.push("/market");
	};

	adminMonitorUser = (userCode, userData) => {
		// console.log(userData);
		if (userCode === null) {
			return this.promisifiedSetState({
				adminMonitoredUserCode: null,
				adminMonitoredUserData: null,
				clientSubscriptions: null,
				clientCurrentSubscription: null,
				clientCurrentOptions: null,
				clientLastSubscription: null,
				// clientCurrentCompaniesExtras: null,
				userAppAccess: {
					profile: null,
					// level: null,
					maxCompanies: 0,
					secondaryAccounts: 0,
					freeTrial: null,
					invited: null,
					isSecondaryAccount: null,
					secondaryAccountEndAccessDate: null,
					endDate: null,
					companiesManagementAuthorised: null,
					featuresAccess: {},
				},
			});
			// localStorage.setItem('monitoreduser', '');
		} else if (userData) {
			return this.promisifiedSetState({
				adminMonitoredUserCode: userCode,
				adminMonitoredUserData: userData,
			}).then(() => this.retrieveClientActiveSubscriptions());
			// localStorage.setItem('monitoreduser', userCode);
		}
	};

	/*
	updateMonitoredUser = (monitoredUserCode) => {
	
		this.promisifiedSetState({adminMonitoredUserCode: monitoredUserCode})
		.then(() => {
			this.retrieveClientActiveSubscriptions();
		});
			
		const authBaseUrl = config.auth.server[window.serverName];
		const authUri = config.auth.uri;
		
		const reqConfig = {
			headers: {
				'x-access-token':localStorage.getItem('token'),
				appname: window.appName,
			}
		};

		axios.get(authBaseUrl + authUri + '/admin/user/' + window.appName + '/' + monitoredUserCode, reqConfig)
		.then((resp) => {
//console.log(resp);
			if (resp && resp.data.success) {
				
				const user = resp.data.data;
				const apiBaseUrl = config.api.server[window.serverName];
				
				// axios.get(apiBaseUrl + config.api.root + '/user/subscriptions/profile/' + monitoredUserCode, reqConfig)
				axios.get(apiBaseUrl + config.api.root + '/user/subscriptions/active/' + monitoredUserCode, reqConfig)
				.then(respAppProfile => {
					
					if (respAppProfile && respAppProfile.data.success && respAppProfile.data.data) {
console.log(respAppProfile.data.data);
						
						this.setState({
							adminMonitoredUserData: {...user, appProfile: respAppProfile.data.data.profile}
						});
					} else {
						
						this.setState({
							adminMonitoredUserData: {...user, appProfile: null}
						});
					}
				})
				.catch(error => {
					console.log(error);
					this.setState({
						adminMonitoredUserData: {...user, appProfile: null}
					});
					if (error.response && error.response.status === 498) {
						this.handleLogout(true);
					}
				});
				
				this.updateUserIdentity(user.firstname, user.lastname + " *** MODE ADMIN ***", user.email, user.firm, user.job, user.phone, user.type, user.created);
			}
		})
		.catch(error => {
			if (error.response && error.response.status === 498) {
				this.handleLogout(true);
			}
			console.log(error);
		});
	}
	*/

	handleToSubscribe = (event) => {
		document.body.click();
		if (event) event.preventDefault();
		this.props.history.push("/subscribe");
	};

	handleToAdvisorParameters = (event) => {
		document.body.click();
		if (event) event.preventDefault();
		this.props.history.push("/advisor");
	};

	handleToSecondaryAccounts = (event) => {
		document.body.click();
		if (event) event.preventDefault();
		this.props.history.push("/users");
	};

	/*
	handleToKnowledge = () => {
		document.body.click();
		this.logUserAction("Nav [Menu] to /knowledge");
		this.props.history.push('/knowledge');
	}
	*/

	processNextSessionStep = (newSessionFlag, crmStep, url) => {
		if (!(this.state.userCompanySessionFlag & sessionParameters.companySessionFlags[url])) {
			/*
			if (config.gtmTracking && typeof window.dataLayer !== "undefined") {
				window.dataLayer.push({
					"event": "initiate_project_step",
					"email": this.props.userEmail,
					"siren": this.props.userSiren,
					"content_category": "use_app_action",
					"content_type": "initiate_project_build"
				});
			}
			*/

			crmLogProjectStep({
				step: crmStep,
				siren: this.state.userSiren,
			});
		}

		this.updateCompanySessionFlag(newSessionFlag);
		this.logUserAction("Nav [Step] to " + url);
		this.props.history.push(url);
	};

	processCurrentSessionStep = (newSessionFlag, crmStep, url) => {
		if (!(this.state.userCompanySessionFlag & sessionParameters.companySessionFlags[url])) {
			/*
			if (config.gtmTracking && typeof window.dataLayer !== "undefined") {
				window.dataLayer.push({
					"event": "initiate_project_step",
					"email": this.props.userEmail,
					"siren": this.props.userSiren,
					"content_category": "use_app_action",
					"content_type": "initiate_project_build"
				});
			}
			*/

			crmLogProjectStep({
				step: crmStep,
				siren: this.state.userSiren,
			});
		}

		this.updateCompanySessionFlag(newSessionFlag);
		// this.logUserAction("Nav [Step] to " + url);
		// this.props.history.push(url);
	};

	/*
	handleNavToExternalLink = (event) => {
		event.preventDefault();
		// window.location.replace(event.currentTarget.getAttribute('href'));
		window.open(event.currentTarget.getAttribute('href'), "_blank");
	}
	*/

	writeCookie = (name, value, days) => {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		const expires = date.toUTCString();
		const hostname = window.location.hostname.toLowerCase();
		var cookieDomain = "";
		if (hostname !== "localhost") {
			const hostParts = hostname.split(".");
			if (hostParts.length >= 2) {
				cookieDomain = " domain=." + hostParts[hostParts.length - 2] + "." + hostParts[hostParts.length - 1] + ";";
			}
		}
		document.cookie = name + "=" + value + ";" + cookieDomain + " expires=" + expires + "; path=/";
	};

	readCookie = (name) => {
		const nameEQ = name + "=";
		var ca = document.cookie.split(";");
		for (let i = ca.length - 1; i >= 0; i--) {
			let c = ca[i];
			while (c.charAt(0) === " ") c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
		}
		return "";
	};

	handleManageAppTheme = () => {
		this.setState({showAppThemeManager: true});
	};

	handleCloseAppThemeManager = () => {
		this.setState({showAppThemeManager: false});
	};

	handleToggleConsolidatedView = () => {
		if (this.state.userAppAccess.featuresAccess && this.state.userAppAccess.featuresAccess["company-consolidated-view"]) {
			this.setState(
				(prevState) => ({consolidatedView: !prevState.consolidatedView}),
				() => this.getCompanyChartOfAccounts(this.state.userSiren),
			);
		}
	};

	handleShowTour = (tourName) => {
		this.setState({showTour: tourName}, () => {
			setTimeout(() => {
				this.setState({showTour: null});
			}, 800);
		});
	};

	handleShowFeedback = (feedbackName) => {
		this.setState({showFeedback: feedbackName}, () => {
			setTimeout(() => {
				this.setState({showFeedback: null});
			}, 800);
		});
	};

	handleUpdateAppStyles = (styles) => {
		const appStyles = {...this.state.appStyles};

		const root = document.querySelector(":root");

		styles.forEach(({styleItemName, styleItemValue}) => {
			if (styleItemValue && isValidHexColor(styleItemValue)) {
				const {r, g, b} = hexToRgb(styleItemValue);
				const rgbString = r + ", " + g + ", " + b;
				appStyles["-" + styleItemName] = styleItemValue;
				appStyles["-" + styleItemName + "-rgb"] = rgbString;
				if (root) {
					root.style.setProperty("--fk-" + styleItemName, styleItemValue);
					root.style.setProperty("--fk-" + styleItemName + "-rgb", rgbString);
				}
			}
		});

		this.setState({appStyles: appStyles});
	};

	render() {
		// console.log(this.state.showSimulTuto);
		// console.log(this.state);
// console.log(window.location);

		if (
			this.state.isLoadingUserToken ||
			this.state.isLoadingClientSubscriptions ||
			this.state.isLoadingUserActivation ||
			this.state.isLoadingUserSiren ||
			this.state.isLoadingUserProfile ||
			this.state.isLoadingUserSessionFlag ||
			this.state.isLoadingUserCompanySessionFlag ||
			this.state.isLoadingWhiteLabelData
		) {
			return null;
		}

		if (window.serverName === "ows") {
			return (
				<div className="app404">
					<p>Cet environnement est désactivé pour le moment.</p>
					<p>
						Connectez-vous sur{" "}
						<button className="linkBtn" href="https://app.forekasts.fr/login" onClick={navToExternalLink}>
							https://app.forekasts.fr/login
						</button>
						.
					</p>
				</div>
			);
		}

		if (this.state.isWhiteLabel && !this.state.isAppNameActive) {
			return (
				<React.Fragment>
					<Helmet>
						<title />
						<link rel="icon" href="about:blank" />
						<meta name="theme-color" content="#ffffff" />
					</Helmet>
					<div className="app404">Cette page semble être inactive.</div>
				</React.Fragment>
			);
		}

		const curPagePathName = this.props.location.pathname;

		const isPageHomepage = curPagePathName === "/login" || curPagePathName === "/signup" || curPagePathName === "/maintenance";

		const childProps = {
			...this.state,
			updateUserCode: this.updateUserCode,
			updateUserActivation: this.updateUserActivation,
			updateUserSiren: this.updateUserSiren,
			updateUserCompanyDates: this.updateUserCompanyDates,
			retrieveUserSiren: this.retrieveUserSiren,
			// updateUserNewCompanyYear: this.updateUserNewCompanyYear,
			// updateUserNewCompanyClosingMonth: this.updateUserNewCompanyClosingMonth,
			// updateUserNewCompanyLongFirstFiscalYear: this.updateUserNewCompanyLongFirstFiscalYear,
			updateUserCompanyData: this.updateUserCompanyData,
			// updateUserCompany: this.updateUserCompany,
			// updateCountry: this.updateCountry,
			// updateCompanyLocalId: this.updateCompanyLocalId,
			// updateCurrency: this.updateCurrency,
			// updateCompanyLegalForm: this.updateCompanyLegalForm,
			updateUserIdentity: this.updateUserIdentity,
			saveUserIdentityData: this.saveUserIdentityData,
			updateUserToken: this.updateUserToken,
			// retrieveUserSessionFlag: this.retrieveUserSessionFlag,
			updateUserSessionFlag: this.updateUserSessionFlag,
			retrieveCompanySessionFlag: this.retrieveCompanySessionFlag,
			updateCompanySessionFlag: this.updateCompanySessionFlag,
			updateUserProfile: this.updateUserProfile,
			updateTawkId: this.updateTawkId,
			logUserAction: this.logUserAction,
			handleToWelcome: this.handleToWelcome,
			handleToSubscribe: this.handleToSubscribe,
			handleToAdvisorParameters: this.handleToAdvisorParameters,
			handleToSecondaryAccounts: this.handleToSecondaryAccounts,
			handleToProject: this.handleToProject,
			handleToAdminBoard: this.handleToAdminBoard,
			handleToDashboard: this.handleToDashboard,
			handleToMarketData: this.handleToMarketData,
			adminMonitorUser: this.adminMonitorUser,
			updateActiveAccounts: this.updateActiveAccounts,
			updateCompanyUseCase: this.updateCompanyUseCase,
			updateActiveFec: this.updateActiveFec,
			handleCreateAccountNumber: this.handleCreateAccountNumber,
			getCompanyChartOfAccounts: this.getCompanyChartOfAccounts,
			retrieveClientActiveSubscriptions: this.retrieveClientActiveSubscriptions,
			logout: this.handleLogout,
			processNextSessionStep: this.processNextSessionStep,
			processCurrentSessionStep: this.processCurrentSessionStep,
			initUserAuthData: this.initUserAuthData,
			initUserAppData: this.initUserAppData,
			readCookie: this.readCookie,
			writeCookie: this.writeCookie,
			// showCompanyTuto: this.handleToCompanyTuto,
			// showProjectTuto: this.handleToProjectTuto,
			// showSimulTuto: this.handleToSimulTuto,
			handleToggleConsolidatedView: this.handleToggleConsolidatedView,
			handleShowTour: this.handleShowTour,
			handleShowFeedback: this.handleShowFeedback,
			handleManageAppTheme: this.handleManageAppTheme,
			handleUpdateAppStyles: this.handleUpdateAppStyles,
		};

		const appNameForHelmet = this.state.isWhiteLabel && this.state.isCoBranded ? config.defaultAppName : window.appName;

		const subscriptionBanner =
			this.state.clientLastSubscription && this.state.clientLastSubscription.expired ? (
				<div className="subscriptionBanner expiredBanner">
					Accès expiré le{" "}
					{this.state.clientLastSubscription.end_subscription_date !== null ? dayjs(this.state.clientLastSubscription.end_subscription_date).format("DD/MM/YYYY") : ""}
				</div>
			) : (
				this.state.userAppAccess.freeTrial && (
					<div className="subscriptionBanner freeTrialBanner">
						<OverlayTrigger
							trigger={["click"]}
							rootClose
							placement="right"
							overlay={
								<Popover id="freeTrialBannerPop">
									<Popover.Body>
										{window.appName !== "forekasts-africa" ? (
											<React.Fragment>
												Pour accéder à l&apos;ensemble des fonctionnalités,{" "}
												<button className="linkBtn" onClick={this.handleToSubscribe}>
													souscrivez un abonnement
												</button>
												.
											</React.Fragment>
										) : (
											<React.Fragment>Pour accéder à l&apos;ensemble des fonctionnalités, souscrivez un abonnement.</React.Fragment>
										)}
									</Popover.Body>
								</Popover>
							}
						>
							<span>
								Essai gratuit
								{this.state.userAppAccess.endDate !== null ? " jusqu'au " + dayjs(this.state.userAppAccess.endDate).format("DD/MM/YYYY") : ""}
							</span>
						</OverlayTrigger>
					</div>
				)
			);

		return (
			<>
				<div className="helpDeskZone" />

				{(!this.state.isWhiteLabel || this.state.isCoBranded) && config.appParams[appNameForHelmet] ? (
					<Helmet>
						<title>{config.appParams[appNameForHelmet].capitalizedName}</title>
						<link rel="shortcut icon" href={"/" + appNameForHelmet + "_favicon.ico?v=kubeg3Gef56"} />
						<link rel="apple-touch-icon" sizes="180x180" href={"/" + appNameForHelmet + "_apple-touch-icon.png?v=kubeg3Gef56"} />
						<link rel="icon" type="image/png" sizes="32x32" href={"/" + appNameForHelmet + "_favicon-32x32.png?v=kubeg3Gef56"} />
						<link rel="icon" type="image/png" sizes="16x16" href={"/" + appNameForHelmet + "_favicon-16x16.png?v=kubeg3Gef56"} />
						<link rel="manifest" href={"/" + appNameForHelmet + "_site.webmanifest?v=kubeg3Gef56"} crossOrigin="use-credentials" />
						<link rel="mask-icon" href={"/" + appNameForHelmet + "_safari-pinned-tab.svg?v=kubeg3Gef56"} color="#18107f" />
						<meta name="msapplication-config" content={"/" + appNameForHelmet + "_browserconfig.xml"} />
						<meta name="msapplication-TileColor" content="#ffffff" />
						<meta name="theme-color" content="#ffffff" />
					</Helmet>
				) : (
					<Helmet>
						<title>{this.state.whiteLabelAppName}</title>
						{this.state.faviconImage ? (
							<link rel="icon" type="image/png" sizes="16x16" href={"data:" + this.state.faviconImage.imageType + ";base64," + this.state.faviconImage.imageBase64} />
						) : (
							<link rel="icon" href="about:blank" />
						)}
						<meta name="theme-color" content="#ffffff" />
					</Helmet>
				)}

				{this.state.userToken && !isPageHomepage && subscriptionBanner}

				<UserAuthContext.Provider value={this.state.userAppAccess}>
					<Routes childProps={childProps} />
				</UserAuthContext.Provider>

				{((this.state.isWhiteLabel && (!this.state.isCoBranded || !this.state.onlineSupport)) || window.appName === "forekasts-africa") &&
					typeof window.Tawk_API === "undefined" && <HelpDeskBtn {...this.props} {...this.state} />}

				<AppThemeManager {...childProps} show={this.state.showAppThemeManager} onHide={this.handleCloseAppThemeManager} />

				<FeedbackManager {...childProps} />
			</>
		);
	}
}

export default withRouter(App);
